import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import I18n from '../shared/FakeI18n'

import Content from '@chaskiq/components/src/components/Content'
import PageHeader from '@chaskiq/components/src/components/PageHeader'
import Progress from '@chaskiq/components/src/components/Progress'
import HeatMap from '@chaskiq/components/src/components/charts/heatMap'
import Pie from '@chaskiq/components/src/components/charts/pie'
import Count from '@chaskiq/components/src/components/charts/count'
import DashboardCard from '@chaskiq/components/src/components/dashboard/card'

import graphql from '@chaskiq/store/src/graphql/client'

import {
  setCurrentSection
} from '@chaskiq/store/src/actions/navigation'

import { DASHBOARD } from '@chaskiq/store/src/graphql/queries'

export function Home() {
  return (
    <div>
      <PageHeader title={'Dashboard'} />
    </div>
  )
}

function Dashboard(props) {
  const { app, dispatch } = props

  React.useEffect(() => {
    dispatch(setCurrentSection('Dashboard'))
  }, [])

  const initialData = {
    loading: true,
    from: moment().add(-1, 'week'),
    to: moment(), // .add(-1, 'day')
  }

  const [dashboard, _setDashboard] = React.useState(initialData)

  return (
    <div>
      <Content>
        <div>
          <div className="flex flex-wrap -mx-4">
            <div className={'w-full p-4'}>
              <DashboardItem
                chartType={'app_packages'}
                dashboard={dashboard}
                app={app}
                label={I18n.t('dashboasrd.user_country')}
                kind={'app_packages'}
                // classes={classes}
                styles={{}}
              />
            </div>

            <div className={'lg:w-1/4 w-1/2 p-4'}>
              <div className="bg-white dark:bg-gray-900 shadow overflow-hidden  sm:rounded-lg p-4">
                <DashboardItem
                  chartType={'count'}
                  dashboard={dashboard}
                  app={app}
                  kind={'first_response_time'}
                  label={I18n.t('dashboard.response_avg')}
                  appendLabel={'Hrs'}
                />
              </div>
            </div>

            <div className={'lg:w-1/4 w-1/2 p-4'}>
              <div className="bg-white dark:bg-gray-900 shadow overflow-hidden  sm:rounded-lg p-4">
                <DashboardItem
                  chartType={'count'}
                  dashboard={dashboard}
                  app={app}
                  kind={'opened_conversations'}
                  label={I18n.t('dashboard.new_conversations')}
                  appendLabel={''}
                />
              </div>
            </div>

            <div className={'lg:w-1/4 w-1/2 p-4'}>
              <div className="bg-white dark:bg-gray-900 shadow overflow-hidden  sm:rounded-lg p-4">
                <DashboardItem
                  chartType={'count'}
                  dashboard={dashboard}
                  app={app}
                  kind={'solved_conversations'}
                  label={I18n.t('dashboard.resolutions')}
                  appendLabel={''}
                />
              </div>
            </div>

            <div className={'lg:w-1/4 w-1/2 p-4'}>
              <div className="bg-white dark:bg-gray-900 shadow overflow-hidden  sm:rounded-lg p-4">
                <DashboardItem
                  chartType={'count'}
                  dashboard={dashboard}
                  app={app}
                  kind={'incoming_messages'}
                  label={I18n.t('dashboard.incoming_messages')}
                  appendLabel={''}
                />
              </div>
            </div>

            <div className={'w-full p-4'}>
              <div className="bg-white dark:bg-gray-900 shadow overflow-hidden  sm:rounded-lg p-4">
                {/* <Chart /> */}
                <div className="mt-1 text-3xl leading-9 font-semibold text-gray-900 dark:text-gray-100">
                  {I18n.t('dashboard.visit_activity')}
                </div>

                <DashboardItem
                  chartType={'heatMap'}
                  dashboard={dashboard}
                  app={app}
                  kind={'visits'}
                />
              </div>
            </div>
            {/* Recent Deposits */}
            <div className={'lg:w-1/4 w-1/2 p-4'}>
              <div className="bg-white dark:bg-gray-900 shadow overflow-hidden  sm:rounded-lg p-4">
                <DashboardCard title={I18n.t('dashboard.users_browser')}>
                  <DashboardItem
                    chartType={'pie'}
                    dashboard={dashboard}
                    app={app}
                    label={I18n.t('dashboasrd.browser')}
                    kind={'browser'}
                  />
                </DashboardCard>
              </div>
            </div>

            <div className={'lg:w-1/4 w-1/2 p-4'}>
              <div className="bg-white dark:bg-gray-900 shadow overflow-hidden  sm:rounded-lg p-4">
                <DashboardCard title={I18n.t('dashboard.lead_os')}>
                  <DashboardItem
                    chartType={'pie'}
                    dashboard={dashboard}
                    app={app}
                    label={I18n.t('dashboasrd.lead_os')}
                    kind={'lead_os'}
                  />
                </DashboardCard>
              </div>
            </div>

            <div className={'lg:w-1/4 w-1/2 p-4'}>
              <div className="bg-white dark:bg-gray-900 shadow overflow-hidden  sm:rounded-lg p-4">
                <DashboardCard title={I18n.t('dashboard.user_os')}>
                  <DashboardItem
                    chartType={'pie'}
                    dashboard={dashboard}
                    app={app}
                    label={I18n.t('dashboasrd.user_os')}
                    kind={'user_os'}
                  />
                </DashboardCard>
              </div>
            </div>

            <div className={'lg:w-1/4 w-1/2 p-4'}>
              <div className="bg-white dark:bg-gray-900 shadow overflow-hidden  sm:rounded-lg p-4">
                <DashboardCard title={I18n.t('dashboard.user_country')}>
                  <DashboardItem
                    chartType={'pie'}
                    dashboard={dashboard}
                    app={app}
                    label={I18n.t('dashboasrd.user_country')}
                    kind={'user_country'}
                  />
                </DashboardCard>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </div>
  )
}

function DashboardItem({
  app,
  kind,
  dashboard,
  chartType,
  label,
  appendLabel,
  classes,
  styles,
}) {
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    getData()
  }, [])

  function getData() {
    graphql(
      DASHBOARD,
      {
        appKey: app.key,
        range: {
          from: dashboard.from,
          to: dashboard.to,
        },
        kind: kind,
      },
      {
        success: (data) => {
          setData(data.app.dashboard)
          setLoading(false)
        },
        error: (_err) => {
          setLoading(false)
        },
      }
    )
  }

  function renderChart() {
    switch (chartType) {
      case 'heatMap':
        return <HeatMap data={data} from={dashboard.from} to={dashboard.to} />

      case 'pie':
        return <Pie data={data} from={dashboard.from} to={dashboard.to} />
      case 'count':
        return (
          <Count
            data={data}
            from={dashboard.from}
            to={dashboard.to}
            label={label}
            appendLabel={appendLabel}
          />
        )
      case 'app_packages':
        return (
          <DashboardAppPackages
            data={data}
            dashboard={dashboard}
            classes={classes}
          />
        )
      default:
        return <p>no chart type</p>
    }
  }

  return (
    <div style={styles || { height: '140px' }}>
      {loading && <Progress />}
      {!loading && renderChart()}
    </div>
  )
}

function DashboardAppPackages(props) {
  const packages = props.data
  return (
    packages &&
    packages.map((o) => (
      <div
        key={`appPackage-${o.name}`}
        className="bg-white dark:bg-gray-900 shadow overflow-hidden  sm:rounded-lg p-4"
      >
        <DashboardAppPackage
          package={o}
          dashboard={props.dashboard}
          classes={props.classes}
        />
      </div>
    ))
  )
}

function DashboardAppPackage(props) {
  const dashboard = props.dashboard
  const pkg = props.package
  const data = pkg.data

  return (
    <div className="p-4">
      <div className="flex mb-2">
        <div className="mr-4">
          <img src={pkg.icon} width={64} />
        </div>

        <div>
          <p className="mt-1 text-3xl leading-9 font-semibold text-gray-900 dark:text-gray-100">
            {pkg.name}: {data.title}
          </p>

          <p className="text-sm leading-5 font-medium text-gray-500 truncate">
            {data.subtitle}
          </p>
        </div>
      </div>

      <hr className="my-4 border-gray-200" />

      <div className="flex">
        {data.values &&
          data.values.map((v, i) => {
            return (
              <div className="w-1/4" key={`count-${i}`}>
                <Count
                  data={v.value}
                  from={dashboard.from}
                  to={dashboard.to}
                  label={v.label}
                  subtitle={`${v.value2}`}
                  // appendLabel={appendLabel}
                />
              </div>
            )
          })}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  const { auth, app } = state
  const { loading, isAuthenticated } = auth

  return {
    app,
    loading,
    isAuthenticated,
  }
}

export default withRouter(connect(mapStateToProps)(Dashboard))
